
import React, { useState } from "react";
import styled from "styled-components";
/*import './Prod.css'*/
import './Produce.css'
const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginBottom:'20px',
 
}));

const Card = styled.div`
  position: relative;
  flex-basis: 100%;
  max-width: 420px;
  min-width:220px;
  height: 330px;
  paddingTop:0;
  
`;

const CardTemplate = styled("div")(() => ({
  width: "100%",
  backfaceVisibility: "hidden",
  height: "330px",
 /* borderRadius: "6px",*/
 
  transformStyle: "preserve-3d",
  transition: "transform 1s cubic-bezier(0.8, 0.3, 0.3, 1)"
}));

const CardFront = styled(CardTemplate)(({ flip }) => ({
  background: " #3FD2C7",
  backgroundSize: "cover",
  backgroundPosition: "center",
  transform: flip ? "rotateY(-180deg)" : "rotateY(0deg)",
  // border:'2px solid black',
  boxShadow: '5px 5px 2px #000911',
  height:"100%" ,
  
}));

const CardBack = styled(CardTemplate)(({ flip }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  background: "#99DDFF",
  transform: flip ? "rotateY(0deg)" : "rotateY(180deg)",
  boxShadow: '5px 5px 2px #000911',
  height:"100%" ,
  // border:"2px solid black",
}));

const CardContent = styled("div")(() => ({
  top: "0",
  position: "absolute",
  left: 0,
  width: "100%",
  backfaceVisibility: "hidden",
  transform: "translateZ(70px) scale(0.90)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  // border:"2px solid yellow",
  height:"100%" 
 
}));

const BGFade = styled("div")(() => ({
  position: "absolute",
  right: 0,
  bottom: 0,
  left: 0,
  height: "100px",

  
}));
export default function Produce(props) {
  const [flip, setFlip] = useState(false);

  return (
    <div className="App">
     
      <Container>
        <Card
          onMouseEnter={() => setFlip(true)}
          onMouseLeave={() => setFlip(false)}
        >
          <CardFront flip={flip}>
            <CardContent>
             <div className="img">
              <div className="titles">{props.title}</div>
                 <img src={props.image} alt=""  className="images"/>
             </div>
            </CardContent>
            <BGFade />
          </CardFront>
          <CardBack flip={flip}>
            <CardContent>
          <div className="back">
            <div className="img_back">
              <div className="titles_back">{props.title}</div>
              </div>
             <div className="img_backs">
               <div className="sections">
                 <div className="titles_backs">CAP </div> 
                 <h3 className="titles_backs2">variations available</h3>
                </div>
              
               <div className="sections1">
                  <div className="titles_backs">{props.colours} </div>
                  <h3 className="titles_backs2">colours available</h3>
               </div> 
              </div>
              <div className="message">
                <div className="titles_backs">{props.message} </div>

              </div>
           </div>
            </CardContent>
          </CardBack>
        </Card>
      </Container>
      {/*
      <Container>
      <Card>
       <Front><content>
       <Back><content>
      </Card>
      */}
    </div>
  );
}


