import React from 'react'
import './Contact.css'
import image from './venuslogo.png';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import EmailIcon from '@mui/icons-material/Email';
function Contact() {
  return (
    <>
    <div className="headings">
        <h2 className='h2s'>CONTACT US </h2>
    </div>
    <div className="google-map-code">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7527.398359739807!2d72.8304291!3d19.3821739!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf2fb73ccf31277da!2sVenus%20Plastics!5e0!3m2!1sen!2sin!4v1659113083896!5m2!1sen!2sin" width="100%" height="650" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div className="contact_block">
        
    <div className="logos">
     <img src={image} alt="" className='imagelogos' />
    </div>
    <div className="infoblock">
        <div className="number1">
          <ContactPhoneIcon className='icon1'/>
          <div className="phone">
            8850503661
          </div>
        </div>
        <div className="number1">
        <ContactPhoneIcon className='icon1'/> 
        <div className="phone">
        Factory : 103, Manish Industrial Estate No. 3, Navghar Vasai (East), Dist. Palghar - 401 202, Mumbai, Maharashtra 401202
          </div>
        </div>
        <div className="number1">
            <EmailIcon className='icon1' />
            <div className="phone">
           venusvasai@gmail.com
          </div>
        </div>
    </div>
    </div>

    </>
  )
}

export default Contact