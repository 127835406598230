import React from 'react'
import './Header.css';
import image from './venuslogo.png';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { Link} from "react-router-dom";
function header() {
  return (<>
  <div className="headerbox">
    <div className="logo">
     <img src={image} alt="" className='imagelogo' />
    </div>
    <div className="navigationbar">
      <Link to='/' style={{textDecoration:'none'}}>
      <div className="icons_header">
        <HomeIcon className='icona'/>
        <div className="homeoption">Home</div>
        </div>
        </Link>
        <Link to='/products' style={{textDecoration:'none'}}>
      <div className="icons_header">
        <StorefrontIcon className='icona'/>
        <div className="homeoption">Products</div>
        </div>
        </Link>
        <Link to='/about' style={{textDecoration:'none'}}>
      <div className="icons_header">
        <PeopleAltIcon className='icona ' />
        <div className="homeoption">About</div>
        </div>
        </Link>
        <Link to='/contact' style={{textDecoration:'none'}}>
      <div className="icons_header">
        <ContactPhoneIcon className='icona ' />
        <div className="homeoption">Contacts</div>
        </div>
        </Link>
 
    </div>
  </div>
   
    </>
  )
}

export default header