import React from 'react'
import './AboutUs.css'
import Fade from 'react-reveal/Fade';
function AboutUs() {
  return (
    <div>
       <div className="aboutus">
    <div className="heading">
        <h2 className='h2'>ABOUT US </h2>
    </div>
    <div className="box">
    <div className="photo">
        <img src="https://5.imimg.com/data5/VB/OG/MY-14059082/altg-hello-master-borosilicate-glass-bottle-with-sleeve-500x500.jpg" alt=""  className='pic'/>
    </div>
    <div className="aboutus_info">
    Venus Plastics is active in Indian Plastic Industries since last 25 years
    Our unit is located at Vasai , Mumbai. We make use of high quality preforms to produce the best bottles and jars you can get in the entire market.
    Our products range mostly between 500ml and 1.2L and there are more than Hundred types and variations .We have Premium Collection of beautifully designed Bottles and Jars with self-design,screen printed and foil printed designs available in multiple colours along with Steel and Plastic Caps in different varities and different Packaging .
    At Venus you are assured of quality products that are provided with highest standard of Technical Support.
    </div>
    </div>
    <div className="goal">
   
      <div className="vision">
        <Fade clear>
        <div className="title">MISSION</div>
        <div className="vision_text">
        To be our customer's first choice when selecting a world-class manufacturer of plastic fridge bottles and Jars.Create and develop advanced technologies and provide outstanding products and services that fulfill the needs of customers worldwide.
        </div>
        </Fade>
      </div>
      <Fade clear>
        <div className="vision">
          <div className="title">VISION</div>
         <div className="vision_text">
            To embrace a top standard for satisfying the needs and expectations of our customers by supplying on-time, high quality products and services, with competitive pricing and long term consistent value.
         </div>
         </div>
      </Fade>
      </div>


   </div>
    </div>
  )
}

export default AboutUs
