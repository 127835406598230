
import './App.css';
import Header from './Header'
import Contact from './Contact';
import Test from './Test';
import Produce from './Produce';
import AboutUs from './AboutUs';
import { BrowserRouter as Router , Routes , Route}
from "react-router-dom";
import Products from './Products';
function App() {
  return (
      <>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<><Header /><Test /></>} />
            <Route exact path='/contact' element={<><Header /><Contact /></>} />
             <Route exact path='/products'element={<><Header/><Products /></>} />
            <Route exact path='/about' element={<><Header /><AboutUs /></>} />
        </Routes>
        </div>
      </Router>
      </>
    
  );
}

export default App;
