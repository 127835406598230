import React from 'react'
import './Test.css'
import { ScrollContainer, ScrollPage,Animator, batch, Fade, MoveOut, Sticky } from "react-scroll-motion";
import Produce from './Produce';
import mini_leo from './Product_pics/leo_500ml_main.jpg'
import silky from './Product_pics/silky_final.jpg'
import floral_jar_300gm from './Product_pics/floral_jar_300gm.jpg'
import CallIcon from '@mui/icons-material/Call';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import { Link} from "react-router-dom";
import Zoom from 'react-reveal/Zoom';
function Test() {
  return (
     <>
<div className="main">
      <ScrollContainer >
      <ScrollPage page={0}>
        <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -400))}>
          <div style={{ fontSize: "3em", color: "#99DDFF", textAlign:'center',marginTop:'350px', paddingTop:'50px' ,zIndex:'-100',textShadow: "1px 1px black", /*border:'2px solid red',*/fontFamily:'Cambria, Cochin, Georgia, Times, Times New Roman, serif'   }}>
            {`Welcome To Venus !!! `}
          </div>
        </Animator>
       </ScrollPage>
          <Zoom top>
          <div className="clia">
              <div
                style={{
                fontSize:'48px',
                color: "#99DDFF" ,
                textShadow: "2px 2px black",
               /* border:'2px solid lightgreen',*/
                textAlign:'center',
                }}
              >
                <b>   About Us </b>
              </div>
          </div>
              </Zoom>
             <Zoom top>       
              
              <div className="clissa">
            <div
            style={{
              display:'flex',
              flexDirection: 'column',
              alignItems:'center',
              position:'relative',
              fontSize: "20pt",
              color: "#99DDFF",
              textShadow: "1px 1px black",
              margin:'auto auto',   
              /* border:'4px solid red',*/
              textAlign:'center',
              objectFit:'contain',
              height: '100%',
              width:'90%'
              
              
            }}
            >
            <div className="desc">
            Venus Plastic is active in Indian Plastic Industries since last 25 years.
            Our unit is located at Vasai near Mumbai City,India. We produce all  kinds of water bottles, jars and caps that are sent throughout the nation, across many states.Our products range mostly between 500ml and 1.2L and there are more than Hundred types and variations which can be seen in Products Section 
            </div>
            <div> </div>
            <Link to='/about' style={{textDecoration:'none'}}>
            <button style={{ fontSize: "1em", backgroundColor:"#fff", marginBottom:'100px',textShadow: "1px 1px #99DDFF",boxShadow:'1px 1px 1px #99DDFF', cursor:'pointer',marginTop:'10px' , width:'100%',color: '#00458B'}}>More About Us</button>
            </Link>
            </div>
            </div>
             
            </Zoom>
   {/*NEW SECTION */} 
            <Zoom top>
         
   <div className="products">
      <div  style={{
              fontSize:'48px',
              color: "#99DDFF" ,
              textShadow: "2px 1px black",
              /*border:'2px solid white',*/
              textAlign:'center',
              marginTop:'30px',
              marginBottom:'50px',
              backgroundColor:'#00458B'
                     }}>
              <b>Products</b>
      </div>
         <div className="clis">
    
         <div style={{
          fontSize:'36pt',
          color:'white',
           marginBottom:'20px',
          textShadow: "2px 2px black",
          width:'100%',
                     }}>
         Our Top 3 Products
         </div>
       
      <div  style={{
             /*border:'2px solid yellow',*/
             display:'flex',
             flexDirection:'row',
             flexWrap:'wrap',
             justifyContent:'space-around',
             textShadow: "1px 1px black",
            backgroundColor:'#00458B',
              maxWidth:'100%',
             
              
              gap:'20px',
              paddingRight:'20px',
              flexBasis:'0',
              flexGrow:'inherit',
              overflowWrap:'break-word',
            }} >
                <Produce title='Silky' image={silky} message='For those who want smooth bottles'colours='4-6'/>
                <Produce title='Leo 500' image={mini_leo} message=' For all our little lions'colours='4-6'/>
                <Produce title='Floral Jar Mini' image={floral_jar_300gm} message='To store mukhwas' colours='3'/>

       <div className="divide2">
         <Link to='/products' style={{textDecoration:'none'}}>
         <button style={{ fontSize: "2em", color: '#00458b',backgroundColor:"#fff", marginBottom:'50px',textShadow: "1px 1px black", cursor:'pointer',marginTop:'10px' }}>For More Products</button>
         </Link>
       </div>
    
      </div>
     </div>
    
      </div>
            
             </Zoom>
             {/*NEW SECTION */}
       

             <div className="clia">
            <div
              style={{
                fontSize:'48px',
                color: "#99DDFF" ,
                textShadow: "2px 2px black",
               /* border:'2px solid lightgreen',*/
                textAlign:'center',
              }}
            >
           <b>   Contact Us </b>
            </div>
            </div>
              <div className="clissa">
          <div
            style={{
              display:'flex',
        flexDirection: 'column',
       alignItems:'center',
              position:'relative',
              fontSize: "20pt",
              color: "#99DDFF",
              textShadow: "1px 1px black",
                 margin:'auto auto',   
            /* border:'4px solid red',*/
              textAlign:'center',
             objectFit:'contain',
              height: '100%',
              width:'100%'
              
             
            }}
          >
            <div className="contact_structure">
           <div className="stackdivs">
  <CallIcon  className='iconsa'/>
  <div className="cont"> +91 8850503661</div>
           </div>
           <div className="stackdivs">
    <CallIcon className='iconsa'/>
  <div className="cont"> +91 9022548615</div>
           </div>
           <div className="stackdivs">
            <EmailIcon className='iconsa'/>
  <div className="cont">venusvasai@gmail.com</div>
           </div>
           <div className="stackdivs">
    <BusinessIcon className='iconsa'/>
  <div className="cont">Factory : 103, Manish Industrial Estate No. 3, Navghar Vasai (East), Dist. Palghar - 401 202, Mumbai, Maharashtra 401202</div>

           </div>
          </div>

         <div> </div>
         <div className="divide2">
         <Link to='/contact' style={{textDecoration:'none'}}>
          <button style={{ fontSize: "1em", backgroundColor:"#fff", marginBottom:'100px',textShadow: "1px 1px #99DDFF",boxShadow:'1px 1px 1px #99DDFF', cursor:'pointer',marginTop:'10px' , width:'50%',color: '#00458B'}}> To Contact Us</button>
          </Link>
          </div>
          </div>
          </div>
         
      </ScrollContainer>
</div>
      </>
  )
}

export default Test