import React from 'react'
import './Products.css'
import Produce from './Produce';
import army from './Product_pics/militry_final.jpg'
import sugar_jar from './Product_pics/sugar_jar.jpg'
import garden from './Product_pics/garden_main.jpg'
import leo from './Product_pics/leo_main.jpg'
import winner from './Product_pics/winner.jpg'
import elegance from './Product_pics/elegance_main.jpg'
import chatai from './Product_pics/chatai_final.jpg'
import blossom from './Product_pics/blossom_main.jpg'
import silky from './Product_pics/silky_final.jpg'
import sun from './Product_pics/sun_main.jpg'
import caroline from './Product_pics/caroline_final.jpg'
import golden_series_1 from './Product_pics/gold_series_main.jpg'
import golden_series_2 from './Product_pics/gold_series_main_2.jpg'
import avengers from './Product_pics/avengers_main.jpg'
import big_boss from './Product_pics/big_boss_main.jpg'
import mini_leo from './Product_pics/leo_500ml_main.jpg'
import cherry from './Product_pics/cherry.jpg'
import boss from './Product_pics/boss_final.jpg'
import supreme from './Product_pics/supreme_main.jpg'
import diamond_cap_series1 from './Product_pics/diamond_cap_series1.jpg'
import diamond_cap_series2 from './Product_pics/diamond_cap_series2.jpg'
import mini_chatai from './Product_pics/chatai_500ml_final.jpg'
import daisy_main from './Product_pics/daisy_main.jpg'
import Bhola_main from './Product_pics/bhola_main.jpg'
import cresent_main from './Product_pics/cresent_main.jpg'
import dottie from './Product_pics/dottie_final.jpg'
import Pushpa from './Product_pics/pushpa_main.jpg'
import Petal from './Product_pics/petal_main.jpg'
import steeple from './Product_pics/steeples.jpg'
import sapphire from './Product_pics/sapphire.jpg'
import diamond from './Product_pics/diamond.jpg'
import lavasa from './Product_pics/lavasa.jpg'
import creta from './Product_pics/creta.jpg'
import vivo from './Product_pics/vivo.jpg'
import foil_jar from './Product_pics/foil_jar.jpg'
import steel_bottom from './Product_pics/steel_bottom.jpg'
import floral_jar_300gm from './Product_pics/floral_jar_300gm.jpg'
import fancy_oil_dispenser from './Product_pics/fancy_oil_dispenser.jpg'
import classic_oil_dispenser from './Product_pics/classic_oil_dispenser.jpg'
import sugar_jar_oil_dispenser from './Product_pics/sugar_jar_oil_dispenser.jpg'
import regular_oil_dispenser from './Product_pics/regular_oil_dispenser.jpg'
import vasu_main from './Product_pics/vasu_main.jpg'
import apna_time_ayega from './Product_pics/apna_time_ayega.jpg'
import sheetal from './Product_pics/sheetal_main.jpg'
import Sona_dottie from './Product_pics/sona_dottie_main.jpg'
import chota_bheem from './Product_pics/chota_bheem.jpg'
import student_of_the_year from './Product_pics/student_of_the_year.jpg'
import small_winner from './Product_pics/small_winner.jpg'
import mercury_jar from './Product_pics/mercury_jar.jpg'
import floral_jar_500gm from './Product_pics/floral_jar_500gm.jpg'
import Fade from 'react-reveal/Fade';
import diamond_cap_colour from './Product_pics/diamond_cap_colour.jpg'
import transparent_cap_gold_foil from './Product_pics/transparent_cap_gold_foil.jpg'
import straight_gold_foil_cap from './Product_pics/straight_gold_foil_cap.jpg'
function Products() {
  return (
    <div className='products'>
       <div className="heading">
        <h2 className='h2'>OUR PRODUCTS</h2>
       </div>
       <div  style={{
           /* border:'2px solid yellow',*/
             display:'flex',
             flexDirection:'row',
             flexWrap:'wrap',
             justifyContent:'space-around',
             textShadow: "1px 1px black",
            backgroundColor:'#00458B',
              maxWidth:'100%',
             
              /*  marginRight:'800px' ,*/
              gap:'20px',
              paddingRight:'20px',
              flexBasis:'0',
              flexGrow:'inherit',
              overflowWrap:'break-word',
              paddingTop:"30px",
            }} >
              <Fade clear>

              <div className="heading">
        <h2 className='h2'>1 litre Bottles</h2>
       </div>
        <Produce title='Leo' image={leo} message='For those with a noble lion heart'colours='4-6'/>
        <Produce title='Silky' image={silky} message='For those who want smooth bottles'colours='4-6'/>
        <Produce title='Supreme' image={supreme} message='For all the Supreme'colours='4-6'/>
        <Produce title='Blossom' image={blossom} message='For those who love blooming flowers'colours='3-4'/>
        <Produce title='Chatai' image={chatai} message='For those who love symmetry and silver'colours='4-6'/>
        <Produce title='Dottie' image={dottie} message='Matching with the polka dots design'colours='4-6'/>
        <Produce title='Garden' image={garden} message='For those who love nature' colours='4-6'/>
        <Produce title='Diamond' image={diamond} message='For all whose desks are filled with Diamonds'colours='4-6'/>
        <Produce title='Creta' image={creta} message='Rich and Royal Experience'colours='4-6'/>
        <Produce title='Pushpa' image={Pushpa} message='A design of Colourful flower leaves'colours='3'/>
        <Produce title='Cresent' image={cresent_main} message='For all those who love the cresent moon'colours='4-6'/>
        <Produce title='Steel Bottom' image={steel_bottom} message='This can turn all bottles into royal '/>
        <Produce title='Elegance' image={elegance} message='The most elite bottle for the elite people'colours='4-6'/>
        <Produce title='Sun' image={sun} message='For those who shine like a sun'colours='4-6'/>
        <Produce title='Golden series' image={golden_series_1} message='Especially for the elite'colours='Natural'/>
        <Produce title='Golden series ' image={golden_series_2} message='Especially for the elite' colours='Natural'/>
        <Produce title='Diamond Series' image={diamond_cap_series1} message='Especially for the elite'colours='4-6'/>
        <Produce title='Diamond Series' image={diamond_cap_series2} message='Especially for the elite'colours='4-6'/>
        <Produce title='Sheetal' image={sheetal} message='To keep all the things Cool' colours='4-6'/>
        <Produce title='Lavasa' image={lavasa} message='Lavish like Lavasa'colours='4-6'/>
        <Produce title='Vasu' image={vasu_main} message='For the blessings of lord Vishnu'colours='4-6'/>
        <Produce title='Bhola' image={Bhola_main} message='The Classic of all times'colours='4-6'/>
        <Produce title='Daisy' image={daisy_main} message='For all our cute little daisies'colours='4'/>
        <Produce title='Boss' image={boss} message='For all those who want to be a Boss'colours='4-6'/>
        <Produce title='Winner' image={winner} message='For born winners 'colours='3-4'/>
        <Produce title='Cherry' image={cherry} message='For all our cute cherries'colours='4-6'/>
    
        </Fade>
       </div>
       <div className="heading">
        <h2 className='h2'>500ml Bottles</h2>
       </div>
       <div  style={{
           /* border:'2px solid yellow',*/
             display:'flex',
             flexDirection:'row',
             flexWrap:'wrap',
             justifyContent:'space-around',
             textShadow: "1px 1px black",
            backgroundColor:'#00458B',
              maxWidth:'100%',
             
              /*  marginRight:'800px' ,*/
              gap:'20px',
              paddingRight:'20px',
              flexBasis:'0',
              flexGrow:'inherit',
              overflowWrap:'break-word',
              paddingTop:"30px",
            }} >
       
       <Fade clear>
        <Produce title='Leo 500' image={mini_leo} message=' For all our little lions'colours='4-6'/>
        <Produce title='Chatai 500' image={mini_chatai} message='For those who love symmetry and silver' colours='4-6'/>
        <Produce title='Caroline' image={caroline} message='For those who have a cute little daughter' colours='4'/>
        <Produce title='Student of the Year' image={student_of_the_year} message='Gift for the best students'colours='4'/>
        <Produce title='Apna Time Ayega' image={apna_time_ayega} message='For good luck 'colours='4'/>
        <Produce title='Winner 500' image={small_winner} message='For our little winners ' colours='3-4'/>
        <Produce title='Avengers' image={avengers} message='For all the avengers superfans 'colours='4'/>
        <Produce title='Petal' image={Petal} message='A design of Colourful flower petals'colours='4'/>
        <Produce title='Sapphire' image={sapphire} message='As shiny as real Sapphire'colours='4'/>
        <Produce title='Steeple' image={steeple} message='Similar to the Holy Church Steeple'colours='4'/>
        <Produce title='Sona Dottie' image={Sona_dottie} message='Matching with the polka design' colours='4'/>
        <Produce title='Vivo' image={vivo} message='Best Bottle for school children'colours='4'/>
        <Produce title='Military 500' image={army} message='Tribute of Venus to the military' colours='3-4'/>
        <Produce title='Chota Bheem' image={chota_bheem} message='Best bottle for all our Chota Bheems'colours='4'/>
        <Produce title='Big Boss' image={big_boss} message='For the Big Boss'colours='4'/>
        </Fade>
        </div>
        <div className="heading">
        <h2 className='h2'>Jars and Oil Dispensers</h2>
       </div>
        <div  style={{
           /* border:'2px solid yellow',*/
             display:'flex',
             flexDirection:'row',
             flexWrap:'wrap',
             justifyContent:'space-around',
             textShadow: "1px 1px black",
            backgroundColor:'#00458B',
              maxWidth:'100%',
             
              /*  marginRight:'800px' ,*/
              gap:'20px',
              paddingRight:'20px',
              flexBasis:'0',
              flexGrow:'inherit',
              overflowWrap:'break-word',
              paddingTop:"30px",
            }} >
       
       <Fade clear>
        <Produce title='Silver Jar' image={foil_jar} message='Best option to be used in kitchen'colours='Silver'/>
        <Produce title='Floral Jar Mini' image={floral_jar_300gm} message='Available in 5 sizes' colours='3'/>
        <Produce title='Floral Jar' image={floral_jar_500gm} message='Available in 5 sizes' colours='3'/>
        <Produce title='Mercury Jar' image={mercury_jar} message='Used for Mukhwas' colours='3'/>
        <Produce title='Sugar Jar' image={sugar_jar}colours='CAP' message='Best option for industiral and residential use'/>
        <Produce title='Fancy Oil Dispenser' image={fancy_oil_dispenser} message='Easily to handle' colours='3'/>
        <Produce title='Regular Oil Dispenser' image={regular_oil_dispenser} message='To store oil'colours='3'/>
        <Produce title='Classic Oil Dispenser' image={classic_oil_dispenser} message='To store oil' colours='3'/>
        <Produce title='Sugar Jar Oil Dispenser' image={sugar_jar_oil_dispenser} message='To store oil' colours='3'/>
        
        </Fade>
        </div>
       

        <div className="heading">
        <h2 className='h2'>Various Cap Variations</h2>
       </div>
       <div  style={{
           /* border:'2px solid yellow',*/
             display:'flex',
             flexDirection:'row',
             flexWrap:'wrap',
             justifyContent:'space-around',
             textShadow: "1px 1px black",
            backgroundColor:'#00458B',
              maxWidth:'100%',
             
              /*  marginRight:'800px' ,*/
              gap:'20px',
              paddingRight:'20px',
              flexBasis:'0',
              flexGrow:'inherit',
              overflowWrap:'break-word',
              paddingTop:"30px",
            }} >
       
       <Fade clear>
       <Produce title='Natural Crown Cap ' image={transparent_cap_gold_foil} message='Special Diamond Caps' colours='Natural'/>
        <Produce title='Colour Crown Cap' image={diamond_cap_colour} message='Special Diamond Caps' colours='4-6'/>
        <Produce title='36mm Gold Foil Cap ' image={straight_gold_foil_cap} message='Special Diamond Caps' colours='4-6'/>
        </Fade>
        </div>
    </div>
  )
}

export default Products
